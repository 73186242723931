import { Button, Grid, Layout } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FilterIcon } from '@enterprise-ui/icons'
import { NovaTable } from '@dlm/common'
import { useMemo } from 'react'
import { startCase } from '../../common/util/stringUtil'
import SubscriptionModal from './SubscriptionModal'
import DeleteNotificationsButton from './components/DeleteNotificationsButton'
import useUser from '../../common/hooks/useUser'

const carrierColumnDefs = [
  {
    headerName: 'Email Address',
    width: 280,
    field: 'email_address',
    isRowHeader: true,
  },
  {
    headerName: 'Notification Type',
    field: 'notification_type',
  },
  {
    headerName: 'Event',
    field: 'event_description',
  },
  {
    headerName: 'SCAC',
    field: 'scac',
  },
  {
    headerName: 'Subcategory',
    field: 'subcategory',
  },
  {
    headerName: 'Alert Status',
    field: 'alert_status',
  },
  {
    headerName: 'Origin',
    field: 'origin',
  },
  {
    headerName: 'Destination',
    field: 'destination',
  },
  {
    headerName: 'Created By',
    field: 'create_by',
  },
]
const internalColumnDefs = [
  {
    headerName: 'Email Address',
    width: 280,
    field: 'email_address',
    isRowHeader: true,
  },
  {
    headerName: 'Slack Webhook IDs',
    field: 'slack_channels',
  },
  {
    headerName: 'Notification Type',
    field: 'notification_type',
  },
  {
    headerName: 'Event',
    field: 'event_description',
  },
  {
    headerName: 'SCAC',
    field: 'scac',
  },
  {
    headerName: 'Subcategory',
    field: 'subcategory',
  },
  {
    headerName: 'Alert Status',
    field: 'alert_status',
  },
  {
    headerName: 'Origin',
    field: 'origin',
  },
  {
    headerName: 'Destination',
    field: 'destination',
  },
  {
    headerName: 'Created By',
    field: 'create_by',
  },
]

const mapSubscriptions = (subscriptions) => {
  const mappedSubscriptions = []

  subscriptions.forEach((subscription) => {
    const scac = subscription?.scac ?? ''
    const subcategory = subscription?.subcategory ?? ''
    const origin = subscription?.origin ?? ''
    const destination = subscription?.destination ?? ''
    const email_address =
      subscription?.receivers?.email_addresses?.join(', ') ?? ''
    const slack_channels =
      subscription?.receivers?.slack_channels?.join(', ') ?? ''
    const id = subscription.id ?? ''

    mappedSubscriptions.push({
      id: id,
      email_address: email_address,
      slack_channels: slack_channels,
      notification_type: startCase(subscription.notification_type),
      event_description: subscription.event_description,
      scac: scac,
      subcategory: startCase(subcategory),
      alert_status: subscription.alert_status,
      origin: origin,
      destination: destination,
      create_by: subscription.create_by,
    })
  })

  return mappedSubscriptions
}

const NotificationsTable = ({
  subscriptionData,
  subscriptionConfig,
  pageNum,
  pageSize,
  subscriptionCount,
  onPaginationChange,
  onModalClose,
  onRowSelect,
  selectedRows,
  setFiltersVisible,
  ...restProps
}) => {
  const rowData = useMemo(
    () => mapSubscriptions(subscriptionData),
    [subscriptionData],
  )

  const {
    access: { isCarrier },
  } = useUser()

  return (
    <>
      <Layout.Body data-testid="notificationsTable" includeRail>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            <Button
              onClick={() => {
                setFiltersVisible(true)
              }}
              aria-label="Show Search Filters"
              data-testid="filters-button"
            >
              <EnterpriseIcon
                icon={FilterIcon}
                size="sm"
                style={{ marginRight: 5 }}
              />
              Show Filters
            </Button>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container>
          <Grid.Item xs={12}>
            <form onSubmit={(e) => e.preventDefault()}>
              <NovaTable
                name="Notifications"
                showHeader
                columnDefs={isCarrier ? carrierColumnDefs : internalColumnDefs}
                rowData={rowData}
                rowCount={subscriptionCount}
                pageNum={pageNum}
                pageSize={pageSize}
                onPaginationChange={onPaginationChange}
                tableActions={
                  <Grid.Container
                    align="center"
                    justify="flex-end"
                    spacing="dense"
                    noWrap
                  >
                    <Grid.Item>
                      <SubscriptionModal
                        subscriptionConfig={subscriptionConfig}
                        onModalClose={onModalClose}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <DeleteNotificationsButton
                        selectedRows={selectedRows}
                        onModalClose={onModalClose}
                      />
                    </Grid.Item>
                  </Grid.Container>
                }
                {...restProps}
                rowSelection="multiple"
                onRowSelect={onRowSelect}
                selectedRows={selectedRows}
              />
            </form>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </>
  )
}

export default NotificationsTable
