import {
  Button,
  Form,
  Grid,
  Heading,
  Modal,
  ToastProvider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlusCircleIcon, HelpIcon } from '@enterprise-ui/icons'
import { useState } from 'react'
import { FormAutocomplete, FormMultiText, FormRadio } from '@dlm/common'
import {
  formatAutoCompleteData,
  formatEventTypes,
  formatNotificationTypes,
} from './util/modalUtil'
import { useForm } from 'react-hook-form'
import { DEFAULT_SUBSCRIPTION_VALUES } from './subscriptionModalConstants'
import subscriptionService from './services/subscriptionService'
import useBusinessPartner from '../../common/hooks/useBusinessPartner'
import useUser from '../../common/hooks/useUser'
import { flatMap, isEmpty, isNil, reject } from 'lodash'

const EMAIL_REGEXP = /\S+@\S+\.\S+/

const SubscriptionModal = ({ subscriptionConfig, onModalClose }) => {
  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: DEFAULT_SUBSCRIPTION_VALUES,
  })
  const { handleSubmit, reset, errors } = formContext
  const makeToast = ToastProvider.useToaster()
  const {
    access: { isCarrier },
  } = useUser()
  const { scac_codes } = useBusinessPartner()

  const [isSubscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false)
  const [selectedNotificationConfigData, setSelectedNotificationConfigData] =
    useState(DEFAULT_SUBSCRIPTION_VALUES)
  const openConfirmModal = () => {
    setSubscriptionModalVisible(true)
  }
  const closeModal = () => {
    reset(DEFAULT_SUBSCRIPTION_VALUES)
    setSelectedNotificationConfigData(DEFAULT_SUBSCRIPTION_VALUES)
    setSubscriptionModalVisible(false)
    onModalClose()
  }
  const onNotificationTypeChange = (value) => {
    reset({
      notification_type: value,
    })
    setSelectedNotificationConfigData(
      subscriptionConfig.find((config) => config.notification_type === value),
    )
  }
  const handleEventType = (value) => {
    let alertStatuses = reject(
      flatMap(selectedNotificationConfigData.events, (event) => {
        if (
          value &&
          value.id === event.code &&
          event.event_details &&
          event.event_details.alert_statuses
        ) {
          return event.event_details.alert_statuses
        }
      }),
      isNil,
    )

    setSelectedNotificationConfigData({
      ...selectedNotificationConfigData,
      alert_statuses: alertStatuses,
    })
    const formValues = formContext.getValues()
    reset({
      ...formValues,
      event_types: value,
      alert_statuses: isEmpty(alertStatuses) ? [] : formValues.alert_statuses,
    })
  }
  const onReset = () => {
    reset(DEFAULT_SUBSCRIPTION_VALUES)
    setSelectedNotificationConfigData(DEFAULT_SUBSCRIPTION_VALUES)
  }

  const onSubmit = (data) => {
    subscriptionService
      .createSubscription(data)
      .then(() => {
        reset(DEFAULT_SUBSCRIPTION_VALUES)
        setSubscriptionModalVisible(false)
        setSelectedNotificationConfigData(DEFAULT_SUBSCRIPTION_VALUES)
        onModalClose()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully created notification.',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: `Error creating notification.`,
        })
      })
  }

  return (
    <>
      <Button type="primary" size="normal" onClick={openConfirmModal}>
        <EnterpriseIcon icon={PlusCircleIcon} className="hc-mr-dense" />
        Add a New Notification
      </Button>
      <Modal
        onRefuse={closeModal}
        isVisible={isSubscriptionModalVisible}
        headingText="Add New Notification"
        className="subscription-modal"
        data-testid="subscriptionModal"
      >
        <Form id="test-form" onSubmit={(e) => e.preventDefault()}>
          <Grid.Container
            className="hc-pa-lg"
            justify="space-between"
            align="center"
            spacing="dense"
          >
            <Grid.Container>
              <Grid.Item xs={12}>
                <FormRadio
                  label={
                    <Heading size={5}> Select a Notification Type </Heading>
                  }
                  formContext={formContext}
                  className="horizontal-radio"
                  onChange={onNotificationTypeChange}
                  options={formatNotificationTypes(subscriptionConfig)}
                  name="notification_type"
                />
              </Grid.Item>
            </Grid.Container>
            <Grid.Container>
              <Grid.Item xs={6}>
                <FormAutocomplete
                  label="Event Type"
                  formContext={formContext}
                  onUpdate={(id, value) => {
                    handleEventType(value)
                  }}
                  options={formatEventTypes(
                    selectedNotificationConfigData.events,
                  )}
                  optionHeight="expanded"
                  renderOption={(item) => {
                    return (
                      <div>
                        <div>{item.otherInfo.firstLine}</div>
                        <div className="hc-fs-min ">
                          {item.otherInfo.secondLine}
                        </div>
                      </div>
                    )
                  }}
                  name="event_types"
                  rules={{ required: 'You must select an Event Type.' }}
                  disableFieldInfo={false}
                  disabled={isEmpty(selectedNotificationConfigData.events)}
                />
              </Grid.Item>

              <Grid.Item xs={6}>
                <FormAutocomplete
                  label="Alert Status"
                  formContext={formContext}
                  multiselect
                  options={formatAutoCompleteData(
                    selectedNotificationConfigData.alert_statuses,
                  )}
                  name="alert_statuses"
                  disableFieldInfo={false}
                  disabled={isEmpty(
                    selectedNotificationConfigData.alert_statuses,
                  )}
                />
              </Grid.Item>
            </Grid.Container>
            <Grid.Container className="hc-pv-normal">
              {!isCarrier ? (
                <Grid.Item xs={6}>
                  <FormAutocomplete
                    label="Subcategory"
                    formContext={formContext}
                    multiselect
                    options={formatAutoCompleteData(
                      selectedNotificationConfigData.subcategories,
                    )}
                    name="subcategories"
                    disableFieldInfo={false}
                    disabled={isEmpty(
                      selectedNotificationConfigData.subcategories,
                    )}
                  />
                </Grid.Item>
              ) : (
                <></>
              )}
              {isCarrier ? (
                <Grid.Item xs={12}>
                  <FormAutocomplete
                    label="SCACs"
                    formContext={formContext}
                    options={formatAutoCompleteData(scac_codes)}
                    name="carrier_scacs"
                    multiselect
                    rules={{ required: 'At least one SCAC is required.' }}
                    disableFieldInfo={false}
                    disabled={
                      selectedNotificationConfigData.notification_type === null
                    }
                  />
                </Grid.Item>
              ) : (
                <Grid.Item xs={6}>
                  <FormMultiText
                    name="scacs"
                    formContext={formContext}
                    label="SCACs"
                    placeholder="Comma separated list of SCACs"
                    disabled={
                      selectedNotificationConfigData.notification_type === null
                    }
                  />
                </Grid.Item>
              )}
            </Grid.Container>
            <Grid.Container>
              <Grid.Item xs={6}>
                <FormMultiText
                  name="origins"
                  formContext={formContext}
                  label="Origin"
                  placeholder="Comma separated list of Origin IDs"
                  disabled={
                    selectedNotificationConfigData.notification_type === null
                  }
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <FormMultiText
                  name="destinations"
                  formContext={formContext}
                  label="Destination"
                  placeholder="Comma separated list of Destination IDs"
                  disabled={
                    selectedNotificationConfigData.notification_type === null
                  }
                />
              </Grid.Item>
            </Grid.Container>
            <Grid.Container className="hc-pt-normal">
              {isCarrier === true ? (
                <Grid.Item xs={12}>
                  <FormMultiText
                    name="emails"
                    formContext={formContext}
                    label="Email Addresses"
                    placeholder="Comma separated list of Email Addresses"
                    rules={{
                      required: 'At least one valid email address is required.',
                      validate: {
                        validEmails: (values) => {
                          let invalidEmails = values.filter(
                            (value) => !EMAIL_REGEXP.test(value),
                          )
                          if (invalidEmails.length > 0) {
                            return `Invalid email addresses: ${invalidEmails.join(
                              ', ',
                            )}`
                          }
                        },
                      },
                    }}
                    disabled={
                      selectedNotificationConfigData.notification_type === null
                    }
                  />
                </Grid.Item>
              ) : (
                <>
                  <Grid.Item xs={6}>
                    <FormMultiText
                      name="emails"
                      formContext={formContext}
                      label="Email Addresses"
                      placeholder="Comma separated list of Email Addresses"
                      rules={{
                        required:
                          'At least one valid email address is required.',
                        validate: {
                          validEmails: (values) => {
                            let invalidEmails = values.filter(
                              (value) => !EMAIL_REGEXP.test(value),
                            )
                            if (invalidEmails.length > 0) {
                              return `Invalid email addresses: ${invalidEmails.join(
                                ', ',
                              )}`
                            }
                          },
                        },
                      }}
                      disabled={
                        selectedNotificationConfigData.notification_type ===
                        null
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <FormMultiText
                      name="slackWebhookIds"
                      formContext={formContext}
                      label={
                        <Grid.Container justify="space-between">
                          <Grid.Item>Slack Webhook IDs</Grid.Item>
                          <Grid.Item>
                            <Tooltip
                              tooltip="Spiderbot webhook setup guide"
                              location="bottom"
                            >
                              <a
                                href="https://targetonline.sharepoint.com/sites/Slack/SitePages/Spiderbot.aspx"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <EnterpriseIcon
                                  icon={HelpIcon}
                                  style={{ marginLeft: 5 }}
                                />
                              </a>
                            </Tooltip>
                          </Grid.Item>
                        </Grid.Container>
                      }
                      placeholder="Comma separated list of Slack Webhook IDs"
                      disabled={
                        selectedNotificationConfigData.notification_type ===
                        null
                      }
                      rules={{
                        validate: {
                          alphanumeric: (val) => {
                            if (val) {
                              console.log(val)
                              const invalidIds = val.filter(
                                (id) => !/^[a-zA-Z0-9]*$/.test(id),
                              )
                              if (invalidIds.length > 0) {
                                return `Invalid webhook IDs: ${invalidIds.join(', ')}`
                              }
                            }
                          },
                        },
                      }}
                    />
                  </Grid.Item>
                </>
              )}
            </Grid.Container>
          </Grid.Container>
          <footer>
            <Grid.Container
              className="hc-pa-sm"
              justify="flex-end"
              align="flex-end"
              spacing="dense"
            >
              <Grid.Item>
                <Button
                  id="reset"
                  type="secondary"
                  disabled={
                    selectedNotificationConfigData.notification_type === null
                  }
                  fullWidth
                  onClick={onReset}
                >
                  Reset
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  id="submit"
                  type="secondary"
                  disabled={
                    selectedNotificationConfigData.notification_type === null ||
                    !isEmpty(errors)
                  }
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </Button>
              </Grid.Item>
            </Grid.Container>
          </footer>
        </Form>
      </Modal>
    </>
  )
}
export default SubscriptionModal
